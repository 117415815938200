import React from 'react'
import Layout from "../components/Layout"
import {SEO} from "../components/Seo";
import UeberUns from "../components/Ueber-uns";

const ueberUns = () => {
    return (
        <Layout>
            <UeberUns/>
        </Layout>
    )
}

export default ueberUns

export const Head = () => (
    <SEO title="Über uns | Möglichkeiten zur Darstellung des Umsatzkostenverfahrens in SAP" />
)
